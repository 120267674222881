import { AxiosInstance } from 'axios'
import {
  GetCandidateNominationParams,
  GetCandidatesParams,
  GrantProgram,
  GrantProgramCandidate,
  GrantProgramCandidateNomination
} from './grantsService.types'
import { apiPaths } from '../apiPaths'
import { PBPListResponse, PBPResponse } from '../types/pbpResponses.types'

type grantsServiceCapabilities = {
  percentClient: AxiosInstance
}

export const grantsService = ({ percentClient }: grantsServiceCapabilities) => ({
  getGrantPrograms: () => percentClient.get<PBPListResponse<GrantProgram>>(`${apiPaths.v1IamGrantPrograms}`),
  getGrantCandidates: (params: GetCandidatesParams) => {
    const { programId, ...queryParams } = params
    return percentClient.get<PBPListResponse<GrantProgramCandidate>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates`,
      { params: queryParams }
    )
  },
  getGrantCandidate: (params: GetCandidateNominationParams) => {
    const { programId, candidateId } = params
    return percentClient.get<PBPResponse<GrantProgramCandidateNomination>>(
      `${apiPaths.v1IamGrantPrograms}/${programId}/candidates/${candidateId}`
    )
  }
})
