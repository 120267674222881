import {
  Button,
  ButtonText,
  CardDashboard,
  ConfirmationModal,
  Modal,
  Flex,
  Spacer,
  AcknowledgeModal
} from '@percent/lemonade'
import { Trans, useTranslation } from 'react-i18next'
import { GrantProgramCandidateReviewProps } from './GrantProgramCandidateReview.types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { GrantReviewQuestion } from './grantProgramReviewQuestion/GrantProgramReviewQuestion.types'
import { GrantProgramReviewQuestion } from './grantProgramReviewQuestion/GrantProgramReviewQuestion'
import styles from './GrantProgramCandidateReview.module.scss'
import { useState } from 'react'

export function GrantProgramCandidateReview({ onClose, organisationName }: Readonly<GrantProgramCandidateReviewProps>) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const { t } = useTranslation()

  // TODO: Get it from useMutation once it's implemented
  const [success, setSuccess] = useState(false)
  const errorMessage = ''

  const reviewerQuestions: GrantReviewQuestion[] = [
    {
      name: 'localCommunityImpact',
      question: t('grants.nominationForm.question.localCommunityImpact')
    },
    {
      name: 'collaborationOpportunities',
      question: t('grants.nominationForm.question.collaborationOpportunities')
    },
    {
      name: 'clubMemberInvolvement',
      question: t('grants.nominationForm.question.clubMemberInvolvement')
    },
    {
      name: 'other',
      question: t('grants.nominationForm.question.other'),
      placeholder: t('grants.nominationForm.question.other.placeholder'),
      type: 'text'
    }
  ]

  const { handleSubmit, values, dirty, isValid, setFieldValue } = useFormik({
    initialValues: reviewerQuestions.reduce((acc, question) => {
      acc[question.name] = ''
      return acc
    }, {}),
    validationSchema: Yup.object().shape(
      reviewerQuestions.reduce((acc, question) => {
        acc[question.name] = question.type === 'number' ? Yup.number().min(0).required() : Yup.string().required()
        return acc
      }, {})
    ),
    onSubmit: async values => {
      // TODO: API call
      setSuccess(true)
    },
    enableReinitialize: true
  })

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true)
  }

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false)
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('grants.nominationForm.review.confirmationModal.success.title')}
      description={t('grants.nominationForm.review.confirmationModal.success.description', {
        organisation: organisationName
      })}
      buttonText={t('button.close')}
      handleClose={() => {
        closeConfirmationModal()
        onClose()
      }}
      viewTestId="review-success-modal"
      buttonTestId="open-next-case-button"
    />
  )

  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('grants.nominationForm.review.confirmationModal.error.title')}
      description={t('grants.nominationForm.review.confirmationModal.error.description')}
      buttonText={t('button.done')}
      handleClose={closeConfirmationModal}
      viewTestId="review-error-modal"
      buttonTestId="close-review-error-modal"
    />
  )

  return (
    <>
      <CardDashboard
        title={t('grants.nominationForm.leaveReview')}
        action={<ButtonText icon="close" onPress={onClose} data-testid="close-review-button" />}
        className={styles.card}
      >
        <Flex className={styles.questionsWrapper}>
          {reviewerQuestions.map(({ name, question, type, placeholder, maxScore }) => (
            <GrantProgramReviewQuestion
              key={name}
              name={name}
              question={question}
              value={values[name]}
              type={type}
              placeholder={placeholder}
              setFieldValue={setFieldValue}
              maxScore={maxScore}
            />
          ))}
        </Flex>
        <Flex justify="flex-end">
          <Button onPress={openConfirmationModal} disabled={!dirty || !isValid}>
            {t('button.submitReview')}
          </Button>
        </Flex>
      </CardDashboard>
      {confirmationModalOpen && (
        <Modal open={confirmationModalOpen} onClose={closeConfirmationModal}>
          {successModal || errorModal || (
            <ConfirmationModal
              title={t('grants.nominationForm.review.confirmationModal.title')}
              // TODO: Loading
              loading={false}
              primaryButtonText={t('button.submitReview')}
              primaryBtnTestId="submit-review-button"
              secondaryButtonText={t('button.cancel')}
              handleSubmit={handleSubmit}
              handleClose={closeConfirmationModal}
            >
              <Trans
                i18nKey="grants.nominationForm.review.confirmationModal.description"
                components={{
                  b: <b />,
                  br: <Spacer axis="vertical" size={4} />
                }}
                values={{
                  organisation: organisationName
                }}
              />
            </ConfirmationModal>
          )}
        </Modal>
      )}
    </>
  )
}
