import { PBPResponse } from '@percent/partner-dashboard/services/types/pbpResponses.types'

import { WalletBalanceServiceCapabilities } from './walletBalanceService.types'
import { AddWalletBalanceProps, DonationWallet } from '../workplaceGiving/workplaceGivingService.types'

export const walletBalanceService = ({ percentClient }: WalletBalanceServiceCapabilities) => ({
  addWalletBalance: ({ amount, accountId }: AddWalletBalanceProps) => {
    return percentClient.post<PBPResponse<DonationWallet>>(`v1/accounts/${accountId}/donation-wallet/add`, { amount })
  },
  addBatchWalletBalance: async (file: File) => {
    return percentClient.post<PBPResponse<DonationWallet[]>>(`v1/donation-wallets/add-batch`, file, {
      headers: {
        'Content-Type': 'text/csv'
      }
    })
  }
})
