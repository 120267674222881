import { GrantProgramCandidatesTable } from './grantProgramCandidatesTable/GrantProgramCandidatesTable'
import { useParams } from 'react-router-dom'
import { useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'

export function GrantProgramCandidatesContainer() {
  const { grantsService } = useServices()
  const { id: programId } = useParams<{ id: string }>()

  const [
    { data: candidatesData, isLoading: isLoadingCandidates, totalResults: totalCandidates },
    { refresh: refreshCandidates, nextPage, previousPage }
  ] = useQueryList(grantsService.getGrantCandidates, { programId })

  return (
    <div data-testid="grant-program-candidates-container">
      <GrantProgramCandidatesTable
        data={candidatesData}
        programId={programId}
        isLoading={isLoadingCandidates}
        totalResults={totalCandidates}
        refresh={refreshCandidates}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </div>
  )
}
