import { ButtonTextV2, Flex, TextArea } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { GrantProgramReviewQuestionProps } from './GrantProgramReviewQuestion.types'
import styles from './GrantProgramReviewQuestion.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export function GrantProgramReviewQuestion({
  name,
  type = 'number',
  question,
  maxScore = 5,
  setFieldValue,
  value,
  placeholder
}: GrantProgramReviewQuestionProps) {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={16}>
      <p className={styles.questionText}>{question}</p>
      {type === 'number' && (
        <Flex direction="column" gap={8}>
          <Flex gap={8} justify="space-between" data-testid={`${name}-score-buttons`}>
            {Array.from({ length: maxScore }, (_, i) => (
              <ButtonTextV2
                key={`${name}-${i + 1}`}
                className={cx({ scoreButton: true, active: value === i + 1 })}
                onClick={() => setFieldValue(name, i + 1)}
              >
                {i + 1}
              </ButtonTextV2>
            ))}
          </Flex>
          <Flex justify="space-between">
            <span className={styles.scoreScaleDescription}>
              {t('grants.nominationForm.question.number.stronglyDisagree')}
            </span>
            <span className={styles.scoreScaleDescription}>
              {t('grants.nominationForm.question.number.stronglyAgree')}
            </span>
          </Flex>
        </Flex>
      )}
      {type === 'text' && (
        <TextArea name={name} placeholder={placeholder} onChange={e => setFieldValue(name, e.target.value)} />
      )}
    </Flex>
  )
}
