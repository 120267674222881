import {
  Badge,
  FlagAvatar,
  Flex,
  Loader,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow
} from '@percent/lemonade'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import { ColumnHeader, GrantProgramCandidatesTableProps } from './GrantProgramCandidatesTable.types'
import { Alpha3Code } from 'i18n-iso-countries'
import { RoutePath } from '../../../layout/Routes'
import { useHistory } from 'react-router-dom'

export const columnsHeaders: ColumnHeader[] = [
  { header: 'table.header.country', isSortable: false },
  { header: 'table.header.organization', isSortable: true },
  { header: 'table.header.nominator', isSortable: true },
  { header: 'table.header.stage', isSortable: false },
  { header: 'table.header.submittedAt', isSortable: true }
]

export function GrantProgramCandidatesTable({
  data,
  programId,
  isLoading,
  previousPage,
  nextPage,
  totalResults
}: Readonly<GrantProgramCandidatesTableProps>) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const columnsPrograms = columnsHeaders.map((column, _index) => ({
    id: t(column.header),
    isSortable: column.isSortable
  }))

  const rowCategoryClicked = (programId: string, candidateId: string) =>
    push(`${RoutePath.GRANT_PROGRAMS}/${programId}/applications/${candidateId}`)

  const formatDate = (date: string) => {
    return format(new Date(date), 'dd MMM yyyy HH:mm')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <TableProvider
      columns={columnsPrograms}
      totalResults={totalResults}
      isLoading={isLoading}
      illustration="no-grant-candidates"
      emptyTableTitle={t('grants.candidatesTable.emptyTable.title')}
      emptyTableDescription={t('grants.candidatesTable.emptyTable.description')}
    >
      <Table>
        <TableBody>
          {data?.map(({ candidateId, organisation, nominatorEmail, currentState, submittedAt }, _index) => (
            <TableRow
              key={`grant-program-application-${candidateId}`}
              data-testid={`grant-program-application-${candidateId}`}
              onClick={() => rowCategoryClicked(programId, candidateId)}
            >
              <TableCell>
                <FlagAvatar code3={organisation.countryCode as Alpha3Code} width={'32px'} />
              </TableCell>
              <TableCell>{organisation.name}</TableCell>
              <TableCell>{nominatorEmail}</TableCell>
              <TableCell>
                <Badge variant={'default'}>{currentState}</Badge>
              </TableCell>
              <TableCell>{formatDate(submittedAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Flex direction="column" p="10px">
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
    </TableProvider>
  )
}
