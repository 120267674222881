import { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { calculatePercentage } from '../../helpers/calculatePercentage'
import { Alert, Tooltip } from '@percent/lemonade'
import { formatMoney, formatMoneyShort } from '@percent/utility'
import { FundraiserProgressBar } from '@percent/domain/fundraiser'
import { FundraiserDetailsProps } from './fundraiser-details.types'
import styled from 'styled-components'
import FlagImage from '../../assets/images/flag.svg'
import HeartImage from '../../assets/images/heart.svg'

dayjs.extend(relativeTime)

const StyledProgressWrapper = styled.div<{ isFundraiserConcluded: boolean }>`
  ${({ theme, isFundraiserConcluded }) => `
      ${
        isFundraiserConcluded &&
        `
          margin-bottom: ${theme.sizes[6]};
        `
      }
    `}
`

const StyledProgressStats = styled.div`
  ${({ theme }) => `
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: ${theme.sizes[2]};

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    `}
`

const StyledProgressStatsAmounts = styled.div`
  ${({ theme }) => `
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: ${theme.sizes[2]};
      font-size: ${theme.fontSizes.bodySmall};
      font-style: normal;
      font-weight: 500;
      line-height: ${theme.sizes[6]};

      span {
        font-size: ${theme.fontSizes.h2};
        font-weight: 600;
        line-height: ${theme.sizes[8]};
        white-space: nowrap;

        display: inline-flex;
        flex-direction: row;
      }

      & > p:first-of-type {
        margin-right: ${theme.sizes[4]};
      }
    `}
`

const StyledGoalStats = styled.div`
  ${({ theme }) => `
      display: flex;
      flex-flow: row;
      margin-top: ${theme.sizes[2]};
      margin-bottom: ${theme.sizes[6]};
      font-size: ${theme.fontSizes.bodySmall};
      font-style: normal;
      font-weight: 400;
      line-height: ${theme.sizes[4]};

      p {
        display: inline-flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin: 0 ${theme.sizes[2]} 0 0;

        img {
          margin-right: ${theme.sizes[1]};
        }

        &:last-of-type {
          margin: 0 0 0 auto;
        }
      }
    `}
`

const StyledProgressStatsPercentage = styled.p`
  ${({ theme }) => `
      margin-left: ${theme.sizes[3]};
      font-size: ${theme.fontSizes.bodySmall};
      font-style: normal;
      font-weight: 500;
      line-height: ${theme.sizes[4]};
      color: ${theme.colors.gray600};
    `}
`

export const FundraiserDetails = ({
  currentAmount,
  goalAmount,
  currency,
  donations,
  endDate,
  showIcons = true,
  locale,
}: FundraiserDetailsProps) => {
  const progressPercentage = calculatePercentage(currentAmount, goalAmount)
  const prevAmount = useRef(currentAmount)
  const heartAnimationPosition = calculatePercentage(
    prevAmount.current,
    goalAmount
  )
  const [isAnimating, setIsAnimating] = useState(false)
  const isFundraiserConcluded = endDate && dayjs(new Date()).isAfter(endDate)

  useEffect(() => {
    if (currentAmount !== prevAmount.current) {
      setIsAnimating(true)
      const animationTimeout = setTimeout(() => {
        setIsAnimating(false)
        prevAmount.current = currentAmount
      }, 1000)

      return () => {
        clearTimeout(animationTimeout)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }, [currentAmount, prevAmount])

  return (
    <StyledProgressWrapper isFundraiserConcluded={!!isFundraiserConcluded}>
      <StyledProgressStats>
        <StyledProgressStatsAmounts>
          <p>
            <span>
              <Tooltip
                content={formatMoney(
                  { amount: currentAmount, currency },
                  { locale }
                )}
              >
                {formatMoneyShort(
                  { amount: currentAmount, currency },
                  { locale }
                )}
              </Tooltip>
            </span>
            {` raised`}
          </p>
          <p>
            <span>
              {formatMoneyShort({ amount: goalAmount, currency }, { locale })}
            </span>
            {` goal`}
          </p>
        </StyledProgressStatsAmounts>
      </StyledProgressStats>
      <FundraiserProgressBar
        progressPercentage={progressPercentage}
        heartAnimationPosition={heartAnimationPosition}
        isAnimating={isAnimating}
      />
      <StyledGoalStats>
        <p>
          {showIcons && (
            <Image
              priority
              src={HeartImage}
              height={24}
              width={24}
              alt={`${donations} donations`}
            />
          )}
          {`${donations} donations`}
        </p>
        <p>
          {endDate && (
            <>
              {showIcons && (
                <Image
                  priority
                  src={FlagImage}
                  height={24}
                  width={24}
                  alt="End Date"
                />
              )}
              {isFundraiserConcluded
                ? 'Ended'
                : `${dayjs(endDate).fromNow(true)} to go`}
            </>
          )}
        </p>
        <StyledProgressStatsPercentage>{`${progressPercentage}%`}</StyledProgressStatsPercentage>
      </StyledGoalStats>

      {isFundraiserConcluded ? (
        <Alert variant="success" title="The fundraising campaign has concluded">
          <p>You can still donate to support the cause</p>
        </Alert>
      ) : null}
    </StyledProgressWrapper>
  )
}
