import { accountService } from './account/accountService'
import { categoryService } from './category/category'
import { nonprofitsService } from './nonprofits/nonprofitsService'
import { reportingService } from './reporting/reportingService'
import { logger } from '@percent/partner-dashboard/capabilities/logger'
import { authService } from './auth'
import { donationService } from './donation/donationService'
import { partnerService } from './partner/partnerService'
import { applicationsService } from './applications/applicationsService'
import { countryService } from './country/countryService'
import { iamService } from './iam/iam'
import { Capabilities } from '@percent/partner-dashboard/capabilities/capabilities.types'
import { workplaceGivingService } from './workplaceGiving/workplaceGivingService'
import { fundraisersService } from './fundraisers/fundraisersService'
import { scimProvisioningService } from './scimProvisioning/scimProvisioningService'
import { walletBalanceService } from './walletBalance/walletBalanceService'
import { grantsService } from './grants/grantsService'

export const services = (capabilities: Capabilities) => ({
  authService: authService(capabilities),
  countryService: countryService(capabilities),
  donationService: donationService(capabilities),
  partnerService: partnerService(capabilities),
  reportingService: reportingService(capabilities),
  nonprofitsService: nonprofitsService(capabilities),
  categoryService: categoryService(capabilities),
  applicationsService: applicationsService(capabilities),
  accountService: accountService(capabilities),
  iamService: iamService(capabilities),
  workplaceGivingService: workplaceGivingService(capabilities),
  fundraisersService: fundraisersService(capabilities),
  scimProvisioningService: scimProvisioningService(capabilities),
  grantsService: grantsService(capabilities),
  walletBalanceService: walletBalanceService(capabilities),
  logger
})

export type Services = ReturnType<typeof services>
