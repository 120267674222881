import { useState } from 'react'

import { APIErrorHandler } from '@percent/partner-dashboard/common/library/APIErrorHandler'
import { APIError, RowError } from './useMutation.types'

export function useMutation<A, B>(
  api: (_: A) => Promise<B>,
  onSuccess?: (_: B) => void,
  onError?: (_: APIError) => void
) {
  const [data, setData] = useState<B | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<
    Record<'code' | 'message' | 'title', string> & {
      reasons?: string[]
      rowErrors?: RowError[]
    }
  >()
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)

  const apiFunc = (payload: A) => {
    let didCancel = false

    setIsLoading(true)
    setSuccess(false)

    const callApi = async () => {
      try {
        const result = await api(payload)
        setSuccess(true)

        if (!didCancel) {
          setIsLoading(false)
          setData(result)
          setErrorMessage('')
        }

        if (onSuccess) {
          await onSuccess(result)
        }
      } catch (err) {
        if (!didCancel) {
          const errorResponse = err?.response?.data?.error

          setErrorMessage(APIErrorHandler(errorResponse))
          setError({
            code: errorResponse?.code || '',
            message: errorResponse?.message || '',
            title: errorResponse?.title || '',
            reasons: errorResponse?.reasons || [],
            rowErrors: errorResponse?.rowErrors || []
          })

          setIsLoading(false)
        }

        if (onError) {
          onError(err)
        }
      }
    }

    callApi()

    return () => {
      didCancel = true
    }
  }

  return [
    {
      data,
      isLoading,
      error,
      errorMessage,
      success
    },
    {
      apiFunc,
      setErrorMessage
    }
  ] as const
}
