import React from 'react'
import styled from 'styled-components'
import {
  AlertProps,
  AlertVariant,
  StyledAlertProps,
  StyledContentWrapperProps,
} from './alert.types'
import { Icon, IconRegistry } from '../icon'
import { Color } from '../../theme'

const AlertColorMap: Record<AlertVariant, Color> = {
  info: 'info400',
  success: 'success400',
  warning: 'warning400',
  error: 'alert400',
}

const AlertBorderColorMap: Record<AlertVariant, Color> = {
  info: 'info100',
  success: 'success100',
  warning: 'warning100',
  error: 'alert100',
}

const AlertBackgroundColorMap: Record<AlertVariant, Color> = {
  info: 'info30',
  success: 'success30',
  warning: 'warning30',
  error: 'alert30',
}

const AlertIconMap: Record<AlertVariant, keyof typeof IconRegistry> = {
  info: 'info',
  success: 'approve',
  warning: 'warning',
  error: 'reject',
}

export const StyledAlert = styled.div<StyledAlertProps>`
  ${({ theme, $variant }) =>
    `
    display: flex;
    justify-content: left;
    border: 1px solid ${theme.colors[AlertBorderColorMap[$variant]]};
    border-left: 2px solid ${theme.colors[AlertColorMap[$variant]]};
    padding: ${theme.sizes[2]} ${theme.sizes[2]} ${theme.sizes[2]} 0px;
    font-size: ${theme.fontSizes.bodySmall};
    background-color: ${theme.colors[AlertBackgroundColorMap[$variant]]};
    text-align: left;
  `}
`

export const StyledIconWrapper = styled.div`
  ${({ theme }) =>
    `
    height: ${theme.sizes[5]};
    padding: 0 ${theme.sizes[2]};
  `}
`

export const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  ${({ theme, $description }) => `
    display: flex;
    flex-direction: column;
    gap: ${$description ? theme.sizes[1] : 0};
    line-height: ${theme.sizes[6]};
    letter-spacing: 0.013rem;
  `}
`

const StyledHeading = styled.h6`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.bodySmall};
    color: ${theme.colors.black};
    font-weight: ${theme.fontWeights.medium};
    margin: 0;
  `}
`

const StyledDescription = styled.p`
  ${({ theme }) =>
    `
    color: ${theme.colors.gray700};
    margin: 0px;
    font-weight: ${theme.fontWeights.regular};

    a {
      text-decoration: underline;
      text-underline-position: under;
      color: ${theme.colors.gray700};
      font-weight: ${theme.fontWeights.regular};
      &:hover {
        color: ${theme.colors.primary400}
      }
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: ${theme.sizes[4]};
    }
  `}
`

const StyledDescriptionBox = styled.div`
  ${({ theme }) =>
    `
    color: ${theme.colors.gray700};
    margin: 0px;
    font-weight: ${theme.fontWeights.regular};

    a {
      text-decoration: underline;
      text-underline-position: under;
      color: ${theme.colors.gray700};
      font-weight: ${theme.fontWeights.regular};
      &:hover {
        color: ${theme.colors.primary400}
      }
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: ${theme.sizes[4]};
    }
  `}
`

export const Alert = ({
  variant,
  title,
  children,
  descriptionVariant = 'p',
}: AlertProps) => (
  <StyledAlert $variant={variant} data-testid={`alert-${variant}`}>
    <StyledIconWrapper>
      <Icon
        name={AlertIconMap[variant]}
        size={6}
        color={AlertColorMap[variant]}
      />
    </StyledIconWrapper>
    <StyledContentWrapper $description={!!children}>
      {title && <StyledHeading>{title}</StyledHeading>}
      {children ? (
        descriptionVariant === 'p' ? (
          <StyledDescription>{children}</StyledDescription>
        ) : (
          <StyledDescriptionBox>{children}</StyledDescriptionBox>
        )
      ) : null}
    </StyledContentWrapper>
  </StyledAlert>
)
