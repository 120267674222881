import { GrantProgramsTable } from './grantProgramsTable/GrantProgramsTable'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useQueryList } from '@percent/partner-dashboard/common/hooks'

export function GrantProgramsContainer() {
  const { grantsService } = useServices()

  const [
    { data: grantPrograms, isLoading: isLoadingPrograms, totalResults: totalPrograms },
    { refresh: refreshGrantPrograms }
  ] = useQueryList(grantsService.getGrantPrograms)

  return (
    <div data-testid="grant-programs-container">
      <GrantProgramsTable
        data={grantPrograms}
        isLoading={isLoadingPrograms}
        totalResults={totalPrograms}
        refresh={refreshGrantPrograms}
      />
    </div>
  )
}
