import { Alpha3Code } from 'i18n-iso-countries'

export enum StatusType {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Flag = 'flag',
  Clear = 'clear',
  Yes = 'yes',
  No = 'no',
  InProgress = 'in progress',
  EmailSent = 'email sent',
  ValidationFailed = 'validation_failed',
  Invalid = 'invalid',
  Verified = 'verified',
  Accepted = 'accepted',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Active = 'ACTIVE',
  RequestedPayment = 'REQUESTED_PAYMENT',
  ReceivedPayment = 'RECEIVED_PAYMENT',
  Disbursed = 'DISBURSED',
  Cancelled = 'CANCELLED',
  Processed = 'processed',
  OrgIsValid = 'Org is valid',
  OrgIsNotValid = 'Org is not valid',
  CannnotDefine = 'cannot_define'
}

export type ValidationSubmissionStatus =
  | StatusType.Pending
  | StatusType.Processed
  | StatusType.Succeeded
  | StatusType.Failed

export enum ValidationRequestRejectionReasonCodes {
  NotEligible = 'not_eligible',
  IncorrectDocumentation = 'incorrect_documentation',
  NotAttributableToRegistry = 'not_attributable_to_registry',
  Other = 'other'
}

export type Relationship = {
  type: 'branch' | 'fiscal_sponsorship'
  relationship: 'sponsor' | 'sponsored' | 'parent' | 'child'
  result: {
    id: string
    name: string
    address?: string
    type?: string
  }
}

export type ValidationSubmission = {
  id: string
  validationInviteId: string
  configuration: ValidationSubmissionConfiguration
  status: ValidationSubmissionStatus
  organisation?: { id?: string; name?: string; countryCode?: Alpha3Code; relationships?: Relationship[] } | null
  organisationId: string | null
  organisationName: string | null
  validationRequestId: string | null
  validationRequestName: string | null
  validationRequest?: ValidationRequest
  monitoringSubscriptionId: string
  agentVerificationId: string
  eligibilitySubscriptionId: string
  eligibility?: Eligibility | null
  createdAt: string
  agentVerificationFirstName: string
  agentVerificationLastName: string
  agentVerificationEmail: string | null
  validationRequestDocumentId?: string
}

export enum OrganisationTypes {
  Nonprofit = 'nonprofit',
  SocialImpact = 'social_impact',
  Education = 'education'
}

export type ValidationRequest = {
  id: string
  name: string
  acceptedAt: string | null
  rejectedAt: string | null
  organisationTypes: OrganisationTypes[]
  rejectionReasonCode: ValidationRequestRejectionReasonCodes | null
  website: string
  countryCode: Alpha3Code
  jiraLink?: string
}

export type ValidationSubmissionDocument = {
  id: string
  validationSubmissionId: string
  url: string
  createdAt: string
}

export type Monitoring = {
  id?: string
  status: string
  results?: {
    complianceStatus: string
    warning?: {
      status: string
    }
    sanction?: {
      status: string
    }
    controversial?: {
      status: string
    }
    commercial?: {
      status: string
    }
    adverseMedia?: {
      status: string
    }
    hateSpeech?: {
      status: string
    }
    registration?: {
      active: string
    }
  }
  jiraLink?: string
}

export type ActivitySubTag = {
  id: string
  name: string
  description: string
  createdAt: string
  updatedAt: string | null
  tag: {
    id: string
    name: string
    description: string | null
    createdAt: string
    updatedAt: string | null
  }
}

export type Eligibility = {
  id?: string
  status: string
  results: {
    eligibilityStatus: string | null
    confirmedActivitySubTags: ActivitySubTag[] | null
    rejectedActivitySubTags: ActivitySubTag[] | null
  }
  jiraLink?: string
}

export enum AgentVerificationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING_REVIEW = 'pending_review',
  PENDING_USER_VERIFICATION = 'pending_user_verification'
}

export enum AgentVerificationRejectionReasonCodes {
  ValidationRequestFailed = 'validation_request_failed',
  UserVerificationExpired = 'user_verification_expired',
  UserFailedPercentReview = 'user_failed_percent_review',
  Reapply = 'reapply',
  FakeEmailUsed = 'fake_email_used',
  NoOnlinePresence = 'no_online_presence',
  InvalidDocumentation = 'invalid_documentation',
  Other = 'other'
}

type AgentVerification = {
  id: string
  firstName: string
  lastName: string
  createdAt: string
  email: string
  status: AgentVerificationStatus
  rejectionReason?: string | null
  rejectionReasonCode?: AgentVerificationRejectionReasonCodes | null
  validationRequestId?: string
  validationRequest: {
    name?: string
  }
  organisationId?: string
  organisation: {
    name?: string
  }
  title?: string
  language?: string
  configuration?: { name?: string }
  validationSubmission?: {
    id: string | null
  }
  jiraLink?: string
}

export type PartnerFields = Record<string, number | string | Array<string | null> | null>
export type Metadata = Record<string, string>

export type SingleValidationSubmission = ValidationSubmission & {
  agentVerificationRejectionReason: string | null
  agentVerificationStatus: string
  configuration: ValidationSubmissionConfiguration & {
    allowedOrganisations: {
      types: OrganisationTypes[]
    }
  }
  configurationId: string
  validationRequestAcceptedAt: string | null
  validationRequestRejectedAt: string | null
  eligibility?: Eligibility
  monitoring?: Monitoring
  agentVerification?: Omit<AgentVerification, 'createdAt' | 'validationRequest' | 'organisation'>
  partnerFields: PartnerFields
  metadata: Metadata | null
}

export type SingleAdminValidationSubmission = {
  id: string
  status: ValidationSubmissionStatus
  createdAt: Date
  partnerId: string
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  partnerFields: PartnerFields | undefined
  metadata: Metadata | undefined
  organisation: { id?: string; name?: string; countryCode?: Alpha3Code; relationships?: Relationship[] } | undefined
  agentVerification: Omit<AgentVerification, 'createdAt' | 'validationRequest' | 'organisation'> | undefined
  eligibility: Eligibility | undefined
  monitoring: Monitoring | undefined
  validationRequest: ValidationRequest | undefined
  configuration: ValidationSubmissionConfiguration & {
    allowedOrganisations: {
      types: OrganisationTypes[]
    }
  }
}

type ValidationSubmissionConfiguration = {
  id: string
  name: string | null
}

export type VerificationSubmissionConfiguration = {
  id: string
  name: string | null
  allowedOrganisationsTypes: OrganisationTypes[]
  allowedActivitySubTags?: string[]
  complianceFlags?: VettingEventScope[]
  exceptionRules?: Array<string | null>
  rejectedOrganisationsTypes?: string[]
}

export type SubTag = {
  id: string
  name: string
  tagId: string
  description: string
  createdAt: string
  updatedAt: string | null
  deprecatedAt: string | null
  isSensitive?: boolean
  isEducation?: boolean
  isEducationStage?: boolean
  highlightedWords?: string[]
}

export type ActivityTag = {
  id: string
  name: string
  description?: string | null
  subTags: SubTag[]
  createdAt?: string
  updatedAt?: string | null
}

export type VettingEventScope = 'sanction' | 'warning' | 'adverseMedia' | 'hateSpeech' | 'controversial' | 'commercial'
