import { CardDashboard, Flex } from '@percent/lemonade'
import styles from '../GrantProgramCandidateDetailsContainer.module.scss'
import { useTranslation } from 'react-i18next'

export function GrantProgramCandidateReviewsCard() {
  const { t } = useTranslation()

  return (
    <Flex direction="column" data-testid="grant-program-candidate-reviews-card" className={styles.cardCtn}>
      <CardDashboard title={t('grants.nominationForm.title.reviews')} data-testid="reviews-details"></CardDashboard>
    </Flex>
  )
}
