import styled from 'styled-components'
import {
  Badge,
  Heading,
  Text,
  Icon,
  ProgressBar,
  Tooltip,
  Card,
} from '@percent/lemonade'
import {
  CardType,
  FeaturedCardProps,
  TitleContentProps,
} from './featured-card.types'
import { formatMoneyShort } from '@percent/utility'
import { useRef, useEffect, useState } from 'react'
import { Skeleton, Box } from '@mantine/core'

const FeaturedCardContent = styled.div<{ isSuggested?: boolean }>`
  ${({ theme, isSuggested }) => `
    display: flex;
    flex-direction: column;
    gap: ${isSuggested ? '0px' : '12px'};
    align-items: flex-start;
    width: 100%;

    @media only screen and (max-width: 767px) {
      width: 100%;
      border-radius:  ${isSuggested ? theme.sizes[2] : theme.sizes[4]};
    }

    h5 {
      font-weight: 600;
      text-align: left;
      margin-bottom: -8px;
      line-height: 32px;
      max-width: 100%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

const FeaturedCardImage = styled.div<{ imgSrc: string; isSuggested?: boolean }>`
  ${({ imgSrc, theme, isSuggested }) => `
    width: 100%;
    height: ${isSuggested ? '150px' : '278px'};
    margin-bottom: ${isSuggested ? '16px' : '24px'};
    background-image: url(${imgSrc});
    background-size: cover;
    background-position: center;
    border-radius: ${isSuggested ? theme.sizes[2] : theme.sizes[4]};
    position: relative;
    border: 1px solid ${theme.colors.gray90};
  `}
`

const OverlayImage = styled.div<{ imgSrc: string }>`
  ${({ theme, imgSrc }) => `
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 40px;
    height: 40px;
    background-image: url(${imgSrc});
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: ${theme.sizes[2]};
    border: 1px solid ${theme.colors.gray90};
    background-color: white;
    background-position: center;
  `}
`

const FeaturedDateDetails = styled.div`
  ${({ theme }) => `
    & > div {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${theme.sizes[2]};
    }

    & p {
      color: ${theme.colors.gray600};
    }
  `}
`

const FeaturedCardButtonInfo = styled.div`
  display: flex;
  align-items: center;

  button {
    ${({ theme }) => `
      margin-right: ${theme.sizes.xs};
    `}
  }
`

const StyledAmount = styled.p<{ isSuggested?: boolean }>`
  margin: 0px;
  margin-top: ${({ isSuggested }) => (isSuggested ? '0px' : '-15px')};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray600};
`

const FeaturedCardWrapper = styled.div`
  cursor: pointer;
`

const TitleWrapper = styled.div`
  width: 100%;
`

export const FeaturedCardSkeleton = () => (
  <div>
    <FeaturedCardContent>
      <Skeleton height={40} width={300} radius={4} />
      <Skeleton height={288} width="100%" radius={4} mt="sm" />
      <Skeleton height={20} width={100} radius={4} mt="sm" />
      <Skeleton height={66} width="100%" mt="sm" />
      <Skeleton height={20} width={100} radius={4} mt="sm" />
    </FeaturedCardContent>
  </div>
)

export function FundraiserCardSkeleton() {
  return (
    <Card>
      <Box w="100%">
        <Box>
          <Skeleton height={288} radius={4} />
          <Skeleton width={200} height={40} radius={4} mt="sm" />
          <Skeleton height={10} mt="sm" />
          <Skeleton width={120} height={20} radius={4} mt="sm" />
        </Box>
      </Box>
    </Card>
  )
}

export function TitleContent({
  title,
  isSuggestedFundraiser,
  isTitleOverflowing,
}: TitleContentProps) {
  const Content = isSuggestedFundraiser ? (
    <Text as="p" size="large" fontWeight="semiBold" overflowEllipsis>
      {title}
    </Text>
  ) : (
    <Heading data-testid="featured-title" level="h5">
      {title}
    </Heading>
  )

  return isTitleOverflowing ? (
    <Tooltip content={title} placement="top">
      <TitleWrapper>{Content}</TitleWrapper>
    </Tooltip>
  ) : (
    Content
  )
}

export const FeaturedCard = (props: Readonly<FeaturedCardProps>) => {
  const {
    type,
    title,
    badgeText,
    organisationName,
    organisationLogo,
    actionButton,
    imgSrc,
    cardButtonInfo,
  } = props

  const titleRef = useRef<HTMLHeadingElement | null>(null)
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false)
  const isSuggestedFundraiser = type === CardType.SUGGESTED_FUNDRAISER

  useEffect(() => {
    if (titleRef.current) {
      const isOverflowing =
        titleRef.current.scrollWidth > titleRef.current.clientWidth
      setIsTitleOverflowing(isOverflowing)
    }
  }, [title])

  return (
    <FeaturedCardWrapper
      data-testid="featured-card-wrapper"
      onClick={actionButton}
    >
      {imgSrc && (
        <FeaturedCardImage
          imgSrc={imgSrc}
          isSuggested={isSuggestedFundraiser}
          data-testid="featured-image"
        >
          {organisationLogo && (
            <Tooltip content={organisationName} placement="top">
              <OverlayImage
                imgSrc={organisationLogo}
                data-testid="organisation-logo"
              />
            </Tooltip>
          )}
        </FeaturedCardImage>
      )}
      <FeaturedCardContent isSuggested={isSuggestedFundraiser} ref={titleRef}>
        {badgeText && (
          <Badge variant="informative">
            <Text as="p" size="small">
              {badgeText}
            </Text>
          </Badge>
        )}

        <TitleContent
          title={title}
          isSuggestedFundraiser={isSuggestedFundraiser}
          isTitleOverflowing={isTitleOverflowing}
        />

        {type === CardType.OPPORTUNITY ? (
          <FeaturedDateDetails>
            {!props.startDate ? null : (
              <div data-testid="date-container">
                <Icon name="calendar" size="m" color="neutral300" />
                <Text
                  as="p"
                  size="small"
                  fontWeight="medium"
                  dataTestId="start-date"
                >
                  {props.startDate}
                </Text>
              </div>
            )}
            <div>
              <Icon
                name={
                  props.locationType === 'virtual' ? 'virtual-meeting' : 'pin'
                }
                size="m"
                color="neutral300"
              />
              <Text as="p" size="small" fontWeight="medium">
                {props?.locationText}
              </Text>
            </div>
          </FeaturedDateDetails>
        ) : (
          <>
            <ProgressBar
              progressPercentage={Math.min(
                (props.total.amount / props.goal.amount) * 100,
                100
              )}
              backgroundColorComplete={
                props.goal.amount >= props.total.amount
                  ? 'info400'
                  : 'success400'
              }
              backgroundColorIncomplete={
                props.goal.amount >= props.total.amount
                  ? 'info400'
                  : 'success400'
              }
              shadeColor="gray90"
              sizeVariant="x-small"
              enableAnimation
            />
            <StyledAmount isSuggested={isSuggestedFundraiser}>
              {formatMoneyShort(props.total, { locale: props.locale })} of{' '}
              {formatMoneyShort(props.goal, { locale: props.locale })}
            </StyledAmount>
          </>
        )}
        <FeaturedCardButtonInfo>{cardButtonInfo}</FeaturedCardButtonInfo>
      </FeaturedCardContent>
    </FeaturedCardWrapper>
  )
}
