import { ReactComponent as Activity } from '../../assets/images/icons/activity.svg'
import { ReactComponent as AddCircle } from '../../assets/images/icons/add-circle.svg'
import { ReactComponent as AddCircleSolid } from '../../assets/images/icons/add-circle-solid.svg'
import { ReactComponent as Add } from '../../assets/images/icons/add.svg'
import { ReactComponent as AgentVerification } from '../../assets/images/icons/agent-verification.svg'
import { ReactComponent as Agents } from '../../assets/images/icons/agents.svg'
import { ReactComponent as Alerts } from '../../assets/images/icons/alerts.svg'
import { ReactComponent as Application } from '../../assets/images/icons/application.svg'
import { ReactComponent as Approve } from '../../assets/images/icons/approve.svg'
import { ReactComponent as ArrowDown } from '../../assets/images/icons/arrow-down.svg'
import { ReactComponent as ArrowLeft } from '../../assets/images/icons/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg'
import { ReactComponent as ArrowUp } from '../../assets/images/icons/arrow-up.svg'
import { ReactComponent as Attach } from '../../assets/images/icons/attach.svg'
import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg'
import { ReactComponent as MenuCategories } from '../../assets/images/icons/menu-categories.svg'
import { ReactComponent as Categories } from '../../assets/images/icons/categories.svg'
import { ReactComponent as CheckBadge } from '../../assets/images/icons/check-badge.svg'
import { ReactComponent as CheckCircle } from '../../assets/images/icons/check-circle.svg'
import { ReactComponent as Check } from '../../assets/images/icons/check.svg'
import { ReactComponent as ChevronDown } from '../../assets/images/icons/chevron-down.svg'
import { ReactComponent as ChevronLeft } from '../../assets/images/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../assets/images/icons/chevron-right.svg'
import { ReactComponent as ChevronUp } from '../../assets/images/icons/chevron-up.svg'
import { ReactComponent as Claims } from '../../assets/images/icons/claims.svg'
import { ReactComponent as Clock } from '../../assets/images/icons/clock.svg'
import { ReactComponent as Close } from '../../assets/images/icons/close.svg'
import { ReactComponent as CodeFile } from '../../assets/images/icons/code-file.svg'
import { ReactComponent as Compliance } from '../../assets/images/icons/compliance.svg'
import { ReactComponent as Coin } from '../../assets/images/icons/coin.svg'
import { ReactComponent as Copy } from '../../assets/images/icons/copy.svg'
import { ReactComponent as CreditCard } from '../../assets/images/icons/credit-card.svg'
import { ReactComponent as MenuDashboard } from '../../assets/images/icons/menu-dashboard.svg'
import { ReactComponent as Delete } from '../../assets/images/icons/delete.svg'
import { ReactComponent as Donate } from '../../assets/images/icons/donate.svg'
import { ReactComponent as DonateOutline } from '../../assets/images/icons/donate-outline.svg'
import { ReactComponent as Download } from '../../assets/images/icons/download.svg'
import { ReactComponent as DropdownArrowDown } from '../../assets/images/icons/dropdown-arrow-down.svg'
import { ReactComponent as DropdownArrowUp } from '../../assets/images/icons/dropdown-arrow-up.svg'
import { ReactComponent as Edit } from '../../assets/images/icons/edit.svg'
import { ReactComponent as Email } from '../../assets/images/icons/email.svg'
import { ReactComponent as Expand } from '../../assets/images/icons/expand.svg'
import { ReactComponent as FirstPage } from '../../assets/images/icons/first-page.svg'
import { ReactComponent as Flag } from '../../assets/images/icons/flag.svg'
import { ReactComponent as Flame } from '../../assets/images/icons/flame.svg'
import { ReactComponent as Fundraise } from '../../assets/images/icons/fundraise.svg'
import { ReactComponent as Heart } from '../../assets/images/icons/heart.svg'
import { ReactComponent as Info } from '../../assets/images/icons/info.svg'
import { ReactComponent as MenuKeys } from '../../assets/images/icons/menu-keys.svg'
import { ReactComponent as LastPage } from '../../assets/images/icons/last-page.svg'
import { ReactComponent as Link } from '../../assets/images/icons/link.svg'
import { ReactComponent as Live } from '../../assets/images/icons/live.svg'
import { ReactComponent as Lock } from '../../assets/images/icons/lock.svg'
import { ReactComponent as LogOut } from '../../assets/images/icons/log-out.svg'
import { ReactComponent as LoveItCircle } from '../../assets/images/icons/love-it-circle.svg'
import { ReactComponent as LoveItHandGive } from '../../assets/images/icons/love-it-hand-give.svg'
import { ReactComponent as MailResend } from '../../assets/images/icons/mail-resend.svg'
import { ReactComponent as MenuHorizontal } from '../../assets/images/icons/menu-horizontal.svg'
import { ReactComponent as Menu } from '../../assets/images/icons/menu.svg'
import { ReactComponent as Modules } from '../../assets/images/icons/modules.svg'
import { ReactComponent as Monitoring } from '../../assets/images/icons/monitoring.svg'
import { ReactComponent as News } from '../../assets/images/icons/news.svg'
import { ReactComponent as Ngos } from '../../assets/images/icons/ngos.svg'
import { ReactComponent as Other } from '../../assets/images/icons/other.svg'
import { ReactComponent as PictureLandscape } from '../../assets/images/icons/picture-landscape.svg'
import { ReactComponent as Pin } from '../../assets/images/icons/pin.svg'
import { ReactComponent as Pound } from '../../assets/images/icons/pound.svg'
import { ReactComponent as Registries } from '../../assets/images/icons/registries.svg'
import { ReactComponent as Reject } from '../../assets/images/icons/reject.svg'
import { ReactComponent as RotateLeft } from '../../assets/images/icons/rotate-left.svg'
import { ReactComponent as RotateRight } from '../../assets/images/icons/rotate-right.svg'
import { ReactComponent as Search } from '../../assets/images/icons/search.svg'
import { ReactComponent as Settings } from '../../assets/images/icons/settings.svg'
import { ReactComponent as ShoppingList } from '../../assets/images/icons/shopping-list.svg'
import { ReactComponent as StopwatchOutline } from '../../assets/images/icons/stopwatch-outline.svg'
import { ReactComponent as Subtract } from '../../assets/images/icons/subtract.svg'
import { ReactComponent as University } from '../../assets/images/icons/university.svg'
import { ReactComponent as UserCircle } from '../../assets/images/icons/user-circle.svg'
import { ReactComponent as Users } from '../../assets/images/icons/users.svg'
import { ReactComponent as UserslCircle } from '../../assets/images/icons/usersl-circle.svg'
import { ReactComponent as Validation } from '../../assets/images/icons/validation.svg'
import { ReactComponent as ViewOff } from '../../assets/images/icons/view-off.svg'
import { ReactComponent as ViewOn } from '../../assets/images/icons/view-on.svg'
import { ReactComponent as Warning } from '../../assets/images/icons/warning.svg'
import { ReactComponent as WindowSettings } from '../../assets/images/icons/window-settings.svg'
import { ReactComponent as Recurring } from '../../assets/images/icons/recurring.svg'
import { ReactComponent as Percent } from '../../assets/images/icons/percent.svg'
import { ReactComponent as VirtualMeeting } from '../../assets/images/icons/virtual-meeting.svg'
import { ReactComponent as Volunteering } from '../../assets/images/icons/volunteering.svg'
import { ReactComponent as VolunteeringCalendar } from '../../assets/images/icons/volunteering-calendar.svg'
import { ReactComponent as Duration } from '../../assets/images/icons/duration.svg'
import { ReactComponent as HelpCenter } from '../../assets/images/icons/help-center.svg'
import { ReactComponent as LockOutline } from '../../assets/images/icons/lock-outline.svg'
import { ReactComponent as Hammer } from '../../assets/images/icons/hammer.svg'
import { ReactComponent as Filters } from '../../assets/images/icons/filters.svg'
import { ReactComponent as CalendarRefresh } from '../../assets/images/icons/calendar-refresh.svg'
import { ReactComponent as MenuVerifications } from '../../assets/images/icons/menu-verifications.svg'
import { ReactComponent as MenuNonprofits } from '../../assets/images/icons/menu-nonprofits.svg'
import { ReactComponent as MenuDonations } from '../../assets/images/icons/menu-donations.svg'
import { ReactComponent as MenuWorkplaceGiving } from '../../assets/images/icons/menu-workplace-giving.svg'
import { ReactComponent as Goodstack } from '../../assets/images/icons/goodstack.svg'
import { ReactComponent as MenuGrants } from '../../assets/images/icons/document-certificate.svg'
import { ReactComponent as Review } from '../../assets/images/icons/review.svg'

export const IconRegistry = {
  activity: Activity,
  'add-circle': AddCircle,
  'add-circle-solid': AddCircleSolid,
  add: Add,
  'agent-verification': AgentVerification,
  agents: Agents,
  alerts: Alerts,
  application: Application,
  approve: Approve,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  attach: Attach,
  calendar: Calendar,
  'menu-categories': MenuCategories,
  categories: Categories,
  'check-badge': CheckBadge,
  'check-circle': CheckCircle,
  check: Check,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  claims: Claims,
  clock: Clock,
  close: Close,
  'code-file': CodeFile,
  compliance: Compliance,
  coin: Coin,
  copy: Copy,
  'credit-card': CreditCard,
  'menu-dashboard': MenuDashboard,
  delete: Delete,
  donate: Donate,
  'donate-outline': DonateOutline,
  download: Download,
  'dropdown-arrow-down': DropdownArrowDown,
  'dropdown-arrow-up': DropdownArrowUp,
  duration: Duration,
  edit: Edit,
  email: Email,
  expand: Expand,
  'first-page': FirstPage,
  flag: Flag,
  flame: Flame,
  fundraise: Fundraise,
  heart: Heart,
  info: Info,
  'menu-keys': MenuKeys,
  'last-page': LastPage,
  link: Link,
  live: Live,
  lock: Lock,
  'log-out': LogOut,
  'love-it-circle': LoveItCircle,
  'love-it-hand-give': LoveItHandGive,
  'mail-resend': MailResend,
  'menu-horizontal': MenuHorizontal,
  menu: Menu,
  modules: Modules,
  monitoring: Monitoring,
  news: News,
  ngos: Ngos,
  other: Other,
  percent: Percent,
  'picture-landscape': PictureLandscape,
  pin: Pin,
  pound: Pound,
  recurring: Recurring,
  registries: Registries,
  reject: Reject,
  review: Review,
  'rotate-left': RotateLeft,
  'rotate-right': RotateRight,
  search: Search,
  settings: Settings,
  'shopping-list': ShoppingList,
  'stopwatch-outline': StopwatchOutline,
  subtract: Subtract,
  university: University,
  'user-circle': UserCircle,
  users: Users,
  'usersl-circle': UserslCircle,
  validation: Validation,
  'view-off': ViewOff,
  'view-on': ViewOn,
  warning: Warning,
  'window-settings': WindowSettings,
  'virtual-meeting': VirtualMeeting,
  volunteering: Volunteering,
  'volunteering-calendar': VolunteeringCalendar,
  'help-center': HelpCenter,
  'lock-outline': LockOutline,
  hammer: Hammer,
  filters: Filters,
  'calendar-refresh': CalendarRefresh,
  'menu-verifications': MenuVerifications,
  'menu-nonprofits': MenuNonprofits,
  'menu-donations': MenuDonations,
  'menu-workplace-giving': MenuWorkplaceGiving,
  'menu-grants': MenuGrants,
  goodstack: Goodstack,
}
