import styled, { css } from 'styled-components'
import { Icon } from '../icon'
import { BannerProps } from './banner.types'

const BannerContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    padding: 0.875rem;
    justify-content: center;
    font-weight: ${theme.fontWeights.regular};
    line-heigth: ${theme.fontSizes.h2};
    background: ${theme.colors.primary400};
    color: ${theme.colors.white};
    z-index: 2000;

    > p {
      font-size: ${theme.fontSizes.buttonText};
      margin: 0;

      a {
        color: ${theme.colors.white};
        text-decoration: underline;
      }
    }
  `}
`

const CloseButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    right: 0.875rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: white;
    width: ${theme.sizes[7]};
    height: ${theme.sizes[7]};
    cursor: pointer;
    transition: width 0.2s ease, height 0.2s ease;

    :hover {
      width: ${theme.sizes[8]};
      height: ${theme.sizes[8]};
    }
  `}
`

export const Banner = ({ show, children, onClose }: Readonly<BannerProps>) => {
  if (!show) return null

  return (
    <BannerContainer>
      {children}
      <CloseButton onClick={onClose} data-testid="close-banner-button">
        <Icon name="close"></Icon>
      </CloseButton>
    </BannerContainer>
  )
}
