import { Badge, CardContent, CardDashboard, FlagAvatar, Flex, Spacer, Text, theme } from '@percent/lemonade'
import { GrantProgramCandidateNomination } from '@percent/partner-dashboard/services/grants/grantsService.types'
import { useTranslation } from 'react-i18next'
import styles from '../GrantProgramCandidateDetailsContainer.module.scss'

export function GrantProgramCandidateNominationCard(data: Readonly<GrantProgramCandidateNomination>) {
  const { t } = useTranslation()

  const { nomination, organisation } = data
  const { nominator, organisationContact, other } = nomination

  const valueOrNotAvailableString = (value?: string | null) => value || t('typography.notAvailable')

  return (
    <Flex direction="column" data-testid="grant-program-candidate-nomination-card" className={styles.cardCtn}>
      <CardDashboard title={t('grants.nominationForm.title.nominator')} data-testid="nominator-details">
        <CardContent title={t('grants.nominationForm.fullName')}>{nominator.fullName}</CardContent>
        <CardContent title={t('grants.nominationForm.email')}>
          <a href={`mailto:${nominator.email}`}>{nominator.email}</a>
        </CardContent>
        <CardContent title={t('grants.nominationForm.hostClub')}>{nominator.hostClub}</CardContent>
      </CardDashboard>

      <Spacer size={8} axis="vertical" />

      <CardDashboard title={t('grants.nominationForm.title.organizationDetails')} data-testid="organization-details">
        <CardContent title={t('grants.nominationForm.organization')}>{organisation.name}</CardContent>
        <CardContent title={t('grants.nominationForm.verificationStatus')}>
          <Badge icon="check-badge" variant="positive">
            {t('grants.nominationForm.verificationStatus.verified')}
          </Badge>
        </CardContent>
        <CardContent title={t('grants.nominationForm.website')} testId="organization-website">
          {organisation.website ? (
            <a href={data.organisation.website} target="_blank" rel="noopener noreferrer">
              {data.organisation.website}
            </a>
          ) : (
            t('typography.notAvailable')
          )}
        </CardContent>
        <CardContent title={t('grants.nominationForm.country')} data-testid="organization-country">
          <Flex align="center" gap={8}>
            <FlagAvatar code3={organisation.country} />
            <span>{organisation.country}</span>
          </Flex>
        </CardContent>
        <CardContent title={t('grants.nominationForm.address')} testId="organization-address">
          {valueOrNotAvailableString(organisation.address)}
        </CardContent>
        <CardContent title={t('grants.nominationForm.registryId')} testId="organization-registry-id">
          {valueOrNotAvailableString(organisation.registryId)}
        </CardContent>
      </CardDashboard>

      <Spacer size={8} axis="vertical" />

      {(organisationContact.fullName || organisationContact.email) && (
        <>
          <CardDashboard
            title={t('grants.nominationForm.title.organizationContact')}
            data-testid="organization-contact-details"
          >
            <CardContent title={t('grants.nominationForm.fullName')} testId="organization-contact-name">
              {organisationContact.fullName}
            </CardContent>
            <CardContent title={t('grants.nominationForm.email')} testId="organization-contact-email">
              <a href={`mailto:${organisationContact.email}`}>{organisationContact.email}</a>
            </CardContent>
          </CardDashboard>
          <Spacer size={8} axis="vertical" />
        </>
      )}

      <CardDashboard title={t('grants.nominationForm.title.nomination')} data-testid="nomination-details">
        <Flex direction="column" gap={8}>
          {other.map(({ question, answer }, index) => (
            <Flex key={index} direction="column" gap={4}>
              <Text fontWeight="semiBold" size="small">
                {question}
              </Text>
              <Text size="small" color={theme.colors.neutral300}>
                {answer}
              </Text>
              <Spacer size={2} axis="vertical" />
            </Flex>
          ))}
        </Flex>
      </CardDashboard>
    </Flex>
  )
}
