import { CardContent, Badge, Drawer, Grid, Text } from '@percent/lemonade'
import { VerificationConfigurationSidebarProps } from './VerificationConfigurationSidebar.types'
import { useTranslation } from 'react-i18next'
import { mapOrganisationTypes } from '../../utils'
import { useMemo } from 'react'
import styles from './VerificationConfigurationSidebar.module.scss'
import { SubTag, OrganisationTypes } from '../../types/application.types'
import kebabCase from 'lodash/kebabCase'

export function VerificationConfigurationSidebar({
  configuration,
  activityTags,
  verificationSubTags
}: Readonly<VerificationConfigurationSidebarProps>) {
  const { t } = useTranslation()
  const activitySubTags = useMemo(() => activityTags?.flatMap(activityTag => activityTag.subTags), [activityTags])
  const truthyExceptionRules = configuration?.exceptionRules?.filter(rule => Boolean(rule))

  const sortedAllowedActivitySubTags = useMemo((): SubTag[] => {
    if (!configuration?.allowedActivitySubTags?.length || !activitySubTags?.length) return []

    return [...configuration.allowedActivitySubTags]
      .map(tagId => activitySubTags.find(subTag => subTag.id === tagId))
      .filter((subtag): subtag is SubTag => !!subtag)
      .sort((a, b) => {
        const aVerified = verificationSubTags?.includes(a.id) || false
        const bVerified = verificationSubTags?.includes(b.id) || false
        if (aVerified !== bVerified) return bVerified ? 1 : -1

        return a.name.localeCompare(b.name)
      })
  }, [configuration?.allowedActivitySubTags, activitySubTags, verificationSubTags])

  const rejectedActivitySubTags = useMemo(
    () =>
      activitySubTags
        ?.filter(subTag => !configuration?.allowedActivitySubTags?.includes(subTag.id))
        ?.sort((a, b) => {
          const aVerified = verificationSubTags?.includes(a.id) || false
          const bVerified = verificationSubTags?.includes(b.id) || false
          if (aVerified !== bVerified) return bVerified ? 1 : -1

          return a.name.localeCompare(b.name)
        }),
    [activitySubTags, configuration?.allowedActivitySubTags, verificationSubTags]
  )

  const getBadgeVariant = (tagId: string) => {
    if (!verificationSubTags?.length) return 'default'
    return verificationSubTags.includes(tagId) ? 'positive' : 'default'
  }

  const getRejectedBadgeVariant = (tagId: string) => {
    if (!verificationSubTags?.length) return 'default'
    return verificationSubTags.includes(tagId) ? 'critical' : 'default'
  }

  const isOnlyEducationOrganisation =
    configuration.allowedOrganisationsTypes?.length === 1 &&
    configuration.allowedOrganisationsTypes.includes(OrganisationTypes.Education)

  const showRejectedTags = !isOnlyEducationOrganisation

  return (
    <Drawer title={t('drawer.configuration.title')} buttonText={t('drawer.configuration.buttonText')} position="right">
      <CardContent>
        {t('drawer.configuration.description', {
          name: configuration.name || t('typography.unnamedProgram')
        })}
      </CardContent>
      {configuration.allowedOrganisationsTypes.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={8}>
                  <Text fontWeight="medium">{t('typography.organisationType')}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text fontWeight="medium">{t('typography.outcome')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              <Grid className={styles.gridInner}>
                <Grid.Col span={8}>
                  <div className={styles.flexContainer}>
                    {configuration.allowedOrganisationsTypes.map(type => (
                      <Badge
                        variant={mapOrganisationTypes(type)?.status}
                        icon={mapOrganisationTypes(type)?.icon}
                        key={`org-types-${type}`}
                      >
                        {t(mapOrganisationTypes(type)?.text as any)}
                      </Badge>
                    ))}
                  </div>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Badge variant="positive" icon="check-badge">
                    {t('typography.approval')}
                  </Badge>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </CardContent>
      )}
      {configuration?.allowedActivitySubTags && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={8}>
                  <Text fontWeight="medium">{t('typography.activityTags')}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text fontWeight="medium">{t('typography.outcome')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            {sortedAllowedActivitySubTags && sortedAllowedActivitySubTags?.length > 0 && (
              <Grid.Col span={12} className={styles.gridColumn}>
                <Grid className={styles.gridInner}>
                  <Grid.Col span={8}>
                    <div className={styles.flexContainer}>
                      {sortedAllowedActivitySubTags.map(tag => (
                        <Badge
                          key={`allowed-activity-sub-tag-${tag.id}`}
                          variant={getBadgeVariant(tag.id)}
                          icon={verificationSubTags?.includes(tag.id) ? 'check-badge' : undefined}
                        >
                          {tag.name}
                        </Badge>
                      ))}
                    </div>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Badge variant="positive" icon="check-badge">
                      {t('typography.approval')}
                    </Badge>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            )}
            {showRejectedTags && rejectedActivitySubTags && rejectedActivitySubTags?.length > 0 && (
              <Grid.Col span={12} className={styles.lastGridColumn}>
                <Grid className={styles.gridInner}>
                  <Grid.Col span={8}>
                    <div className={styles.flexContainer}>
                      {rejectedActivitySubTags.map(tag => (
                        <Badge
                          key={`rejected-activity-sub-tag-${tag.id}`}
                          variant={getRejectedBadgeVariant(tag.id)}
                          icon={verificationSubTags?.includes(tag.id) ? 'reject' : undefined}
                        >
                          {tag.name}
                        </Badge>
                      ))}
                    </div>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Badge variant="critical" icon="reject">
                      {t('typography.rejection')}
                    </Badge>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            )}
          </Grid>
        </CardContent>
      )}

      {truthyExceptionRules && truthyExceptionRules?.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={12}>
                  <Text fontWeight="medium">{t('typography.exceptionRules')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              {truthyExceptionRules.map((exception, index) => (
                <Grid className={styles.gridInner} key={`${kebabCase(exception || '')}-${index}`}>
                  <Grid.Col
                    span={12}
                    className={index < truthyExceptionRules.length - 1 ? styles.exceptionGridColumn : undefined}
                  >
                    <div>{exception}</div>
                  </Grid.Col>
                </Grid>
              ))}
            </Grid.Col>
          </Grid>
        </CardContent>
      )}
      {configuration?.complianceFlags && configuration?.complianceFlags?.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={8}>
                  <Text fontWeight="medium">{t('typography.complianceFlags')}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text fontWeight="medium">{t('typography.outcome')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              <Grid className={styles.gridInner}>
                <Grid.Col span={8}>
                  <div className={styles.flexContainer}>
                    {configuration?.complianceFlags?.map(flag => (
                      <Badge key={`compliance-flag-${flag}`} variant="default">
                        {t(`typography.${flag}`)}
                      </Badge>
                    ))}
                  </div>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Badge variant="critical" icon="reject">
                    {t('typography.rejection')}
                  </Badge>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </CardContent>
      )}
    </Drawer>
  )
}
