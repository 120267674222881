import { Flex, ButtonText, Tabs, theme, Heading, Avatar } from '@percent/lemonade'
import {
  GrantProgramCandidateHeaderProps,
  GrantProgramCandidateHeaderTab,
  GrantProgramCandidateHeaderTabs
} from './GrantProgramCandidateHeader.types'

import styles from './GrantProgramCandidateHeader.module.scss'
import { useTranslation } from 'react-i18next'

export function GrantProgramCandidateHeader({
  candidateDetails,
  onOpenReview,
  isReviewOpen,
  activeTab,
  setActiveTab
}: Readonly<GrantProgramCandidateHeaderProps>) {
  const { t } = useTranslation()
  const { organisation } = candidateDetails
  const tabs: GrantProgramCandidateHeaderTabs = [
    {
      label: t('grants.nominationForm.header.nominationTab'),
      key: 'nomination'
    },
    {
      label: t('grants.nominationForm.header.reviewsTab'),
      key: 'review'
    }
  ]

  return (
    <Flex direction="column" className={styles.header}>
      <Flex justify="space-between" m={theme.sizes['m']} className={styles.heading}>
        <Flex align="center" gap={theme.sizes['s']}>
          <Avatar image={organisation.logo} alt={organisation.name} size="large" />
          <Heading level="h3" data-testid="header-title">
            {organisation.name}
          </Heading>
        </Flex>
        {!isReviewOpen && (
          <ButtonText icon="review" onPress={onOpenReview} data-testid="leave-review-button">
            {t('grants.nominationForm.header.button.leaveReview')}
          </ButtonText>
        )}
      </Flex>
      <Tabs
        items={tabs}
        activeTab={activeTab}
        onChangeTab={newTab => {
          setActiveTab(newTab as GrantProgramCandidateHeaderTab)
        }}
      />
    </Flex>
  )
}
