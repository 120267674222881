import { Flex, Loader, Tab } from '@percent/lemonade'
import { GrantProgramCandidateNominationCard } from './grantProgramCandidateNominationCard/GrantProgramCandidateNominationCard'
import { GrantProgramCandidateHeader } from './grantProgramCandidateHeader/GrantProgramCandidateHeader'
import { useEffect, useState } from 'react'
import { GrantProgramCandidateReview } from './grantProgramCandidateReview/GrantProgramCandidateReview'
import { useQueryParams, StringParam } from 'use-query-params'
import { useParams } from 'react-router-dom'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import styles from './GrantProgramCandidateDetailsContainer.module.scss'
import { GrantProgramCandidateHeaderTab } from './grantProgramCandidateHeader/GrantProgramCandidateHeader.types'
import { GrantProgramCandidateReviewsCard } from './grantProgramCandidateReviewsCard/GrantProgramCandidateReviewsCard'

export function GrantProgramCandidateDetailsContainer() {
  const [activeTab, setActiveTab] = useState<GrantProgramCandidateHeaderTab>('nomination')

  const [queryParams, setQueryParams] = useQueryParams({
    inReview: StringParam
  })
  const [isReviewOpen, setIsReviewOpen] = useState(queryParams.inReview === 'true')

  const { grantsService } = useServices()
  const { id: programId, candidateId } = useParams<{ id: string; candidateId: string }>()

  const [{ data: candidateDetails, isLoading: isLoadingCandidateDetails }] = useQuery(grantsService.getGrantCandidate, {
    programId,
    candidateId
  })

  const handleCloseReview = () => {
    setQueryParams({ inReview: undefined })
  }

  const handleOpenReview = () => {
    setQueryParams({ inReview: 'true' })
  }

  useEffect(() => {
    setIsReviewOpen(queryParams.inReview === 'true')
  }, [queryParams.inReview])

  if (isLoadingCandidateDetails) {
    return <Loader />
  }

  return (
    <Flex gap={16}>
      <Flex data-testid="grant-program-candidate-details-container" className={styles.detailsCtn}>
        <GrantProgramCandidateHeader
          candidateDetails={candidateDetails}
          isReviewOpen={isReviewOpen}
          onOpenReview={handleOpenReview}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Tab value={activeTab} index="nomination">
          <GrantProgramCandidateNominationCard {...candidateDetails} />
        </Tab>
        <Tab value={activeTab} index="review">
          <GrantProgramCandidateReviewsCard />
        </Tab>
      </Flex>
      {isReviewOpen && (
        <div className={styles.reviewPanel}>
          <GrantProgramCandidateReview
            organisationName={candidateDetails.organisation.name}
            onClose={handleCloseReview}
          />
        </div>
      )}
    </Flex>
  )
}
